import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default class CardColor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        className={classNames(
          style.card_color,
          this.props.onClick ? style.card_color_action : {},
          this.props.className
        )}
        onClick={this.props.onClick}
      >
        {this.props.count === 0 || this.props.count ? (
          <div className={style.card_color_left}>
            <span>{this.props.count}</span>
          </div>
        ) : (
          <div className={style.card_color_image_left}>
            <span>{this.props.image}</span>
          </div>
        )}
        <div className={style.card_color_center}>
          <span>{this.props.title}</span>
          <span>{this.props.subtitle}</span>
        </div>
        <div className={style.card_color_right}>
          {this.props.icon ? (
            this.props.icon
          ) : (
            <FontAwesomeIcon icon={faArrowRight} />
          )}
        </div>
      </div>
    )
  }
}
