import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FloatActionButtonItem extends React.Component {
  render() {
    return (
      <div className={style.float_action_item_container}>
        <span className={classNames(style.float_action_item_text, 'nowrap')}>
          {this.props.text}
        </span>
        <button
          className={classNames(style.float_action_item, this.props.className)}
          onClick={this.props.onClick}
        >
          {this.props.icon}
        </button>
      </div>
    )
  }
}
