import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class CardTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0
    }
  }

  render() {
    return (
      <div className={classNames(style.card_tabs, this.props.className)}>
        <div className={style.card_tabs_header}>
          <div className={style.card_tabs_header_left}>
            {this.props.titles.map((title, idx) => (
              <h2
                key={idx}
                className={
                  idx === this.state.active ? style.card_tabs_header_active : ''
                }
                onClick={() => this.setState({ active: idx })}
              >
                {title}
              </h2>
            ))}
          </div>
        </div>
        <div
          className={classNames(
            style.card_tabs_body,
            this.props.classNameChildren
          )}
        >
          {this.props.contents[this.state.active]}
        </div>
      </div>
    )
  }
}
