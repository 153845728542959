import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'
import FormSelect from '../form-select/FormSelect'
import ButtonIcon from '../button/ButtonIcon'

export class Chips extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chipsList: this.props.list ? this.props.list : []
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ chipsList: this.props.list ? this.props.list : [] })
    }
  }

  handleClick = (e) => {
    console.log('e', e)
    let newChipsList = this.state.chipsList
    const { value } = e
    newChipsList = newChipsList.filter((element) => element.value != value)
    this.setState({ chipsList: newChipsList }, () =>
      this.props.onChange(this.state.chipsList)
    )
  }

  handleInputChange = (e) => {
    let newChipsList = this.state.chipsList
    const { value } = e.target
    const index = this.props.options.findIndex(
      (element) => element.value == value
    )
    let isAlreadyInside = false
    newChipsList.map((element) => {
      if (element.value == value || value == 0) isAlreadyInside = true
      return
    })
    if (!isAlreadyInside) {
      newChipsList.push(this.props.options[index])
      this.setState({ chipsList: newChipsList }, () =>
        this.props.onChange(this.state.chipsList)
      )
    }
  }

  render() {
    return (
      <div
        className={classNames(style.component, this.props.className)}
        style={this.props.style}
      >
        <div className={classNames(style['display-chips'])}>
          {this.state.chipsList.length > 0
            ? this.state.chipsList.map((chips, idx) => {
                return (
                  <div key={idx} className={classNames(style.chips)}>
                    <div>{chips.text}</div>
                    <ButtonIcon
                      className={classNames(style.buttonIcon)}
                      onClick={() => this.handleClick(chips)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </ButtonIcon>
                  </div>
                )
              })
            : null}
        </div>
        <div>
          {this.props.disabledOptions ? null : (
            <FormSelect
              hide={this.disabledOptions ? true : false}
              title='Agences'
              name='agency'
              value={this.state.agency ? this.state.agency : undefined}
              ignore={true}
              options={this.props.options}
              onChange={this.handleInputChange}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Chips
