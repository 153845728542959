import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Button extends React.Component {
  render() {
    var buttonClass = classNames({
      [style.button_outline]: !!this.props.outline,
      [style.button]: !this.props.outline,
      [style.button_special]: !!this.props.children,
      [style.small]: !!this.props.small,
      [style.big]: !!this.props.big,
      [style.button_red]: !!this.props.red,
      [style.button_blue]: !!this.props.blue,
      [style.button_orange]: !!this.props.orange,
      [style.button_green]: !!this.props.green,
      [this.props.className]: !!this.props.className
    })

    return (
      <button
        className={buttonClass}
        style={this.props.style}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <span style={this.props.styleSpan}>{this.props.text ? this.props.text.toUpperCase() : ""}</span>
        {this.props.children ? (
          <div
            style={this.props.styleDiv}
            className={classNames(style.button_special_icon)}
          >
            {this.props.children}
          </div>
        ) : (
          ''
        )}
      </button>
    )
  }
}
