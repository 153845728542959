import React from 'react'
import style from './style.css'

export default class ListingHorizontalSeparator extends React.Component {
  render() {
    return (
      <div
        className={style.listing_horizontal_separator}
        style={{ width: this.props.width }}
      />
    );
  }
}
