import React from 'react'
import DropzoneCMP from 'react-dropzone'
import style from './style.css'
import classNames from 'classnames'
import ButtonIcon from '../button/ButtonIcon'

export default class Dropzone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      textInfo: this.typeNameText(this.props.typeName),
      base64: '',
      borderColor: '#5c5c5c',
      styleBtnClose: 'none'
    }
  }

  typeNameText(propsTypeName) {
    if (propsTypeName) {
      const name = propsTypeName.join(', ')
      return `Glisser un fichier ou cliquer pour sélectionner un fichier (type accepté, ${name})`
    } else {
      return 'Glisser un fichier ou cliquer pour sélectionner un fichier'
    }
  }

  typeAccept(acceptedFiles, propsTypeAccept) {
    let value = false
    if (propsTypeAccept) {
      propsTypeAccept.forEach((e) => {
        if (acceptedFiles[0].type === e) {
          value = true
        }
      })
    } else {
      value = true
    }
    return value
  }

  onDrop(acceptedFiles, callback) {
    if (this.typeAccept(acceptedFiles, this.props.typeAccept)) {
      const reader = new window.FileReader()
      reader.onabort = () => {
        this.setState({
          textInfo: "Une erreur s'est produite, veuillez réessayer.",
          borderColor: '#ff0000'
        })
      }
      reader.onerror = () => {
        this.setState({
          textInfo: "Une erreur s'est produite, veuillez réessayer.",
          borderColor: '#ff0000'
        })
      }
      reader.onloadstart = () => {
        this.setState({ loader: true })
      }
      reader.onloadend = () => {
        this.setState({ loader: false })
      }
      reader.onload = () => {
        const base64 = reader.result
        this.setState({ base64: base64 })

        if (callback) {
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            base64: base64,
            mime: acceptedFiles[0].type,
            name: acceptedFiles[0].name,
            size: acceptedFiles[0].size
          })
        }
      }
      acceptedFiles.forEach((file) => reader.readAsDataURL(file))
      this.setState({
        textInfo: `Fichier ${acceptedFiles[0].name} est prêt à être envoyé, glisser ou cliquer pour modifier`,
        borderColor: '#74b741',
        styleBtnClose: 'contents'
      })
    } else {
      this.setState({
        textInfo: 'Format non valide',
        borderColor: '#ff0000'
      })
    }
  }

  render() {
    return (
      <div
        className={classNames(
          style.FormDragDropFile_container,
          this.props.className
        )}
      >
        <DropzoneCMP
          className={classNames(this.props.classNameChildren)}
          onDrop={(acceptedFiles) => {
            this.onDrop(acceptedFiles, (data) => this.props.dropHandler(data))
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ borderColor: this.state.borderColor }}>
              <div
                className={style.btn_close_drag_drop}
                style={{ display: this.state.styleBtnClose }}
              >
                <ButtonIcon
                  ref={this.props.ref}
                  onClick={() =>
                    this.setState(
                      {
                        borderColor: '#5c5c5c',
                        textInfo: this.typeNameText(this.props.typeName),
                        styleBtnClose: 'none'
                      },
                      () => this.props.dropHandler('')
                    )
                  }
                >
                  {/*<FontAwesomeIcon icon={faTimes} /> */}
                </ButtonIcon>
              </div>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <span>{this.state.textInfo}</span>
              </div>
            </section>
          )}
        </DropzoneCMP>
      </div>
    )
  }
}
