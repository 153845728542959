import Holidays from 'date-holidays'
import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Row, Col } from 'react-flexbox-grid'
import style from './style.css'
import classNames from 'classnames'

const hd = new Holidays('FR')

export default class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      days: [],
      activeDate: null,
      times: [],
      activeTime: null
    }

    this.months = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ]
  }

  componentDidMount() {
    this.getArrayDays()
    this.getArrayTime()

    this.setState({
      year: this.props.valueDate
        ? parseInt(moment(this.props.valueDate).format('YYYY'))
        : this.state.year,
      month: this.props.valueDate
        ? parseInt(moment(this.props.valueDate).format('M'))
        : this.state.month,
      activeDate: this.props.valueDate
        ? this.props.valueDate
        : this.state.activeDate,
      activeTime: this.props.valueTime ? this.props.valueTime : this.state.Time
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.show !== this.props.show &&
      this.props.show &&
      this.props.valueDate
    ) {
      this.setState(
        {
          year: parseInt(moment(this.props.valueDate).format('YYYY')),
          month: parseInt(moment(this.props.valueDate).format('M')),
          activeDate: this.props.valueDate
        },
        () => {
          this.getArrayDays()
        }
      )
    } else if (prevProps.show !== this.props.show) {
      this.getArrayDays()
    }

    if (
      prevProps.show !== this.props.show &&
      this.props.show &&
      this.props.valueTime
    ) {
      this.setState(
        {
          activeTime: this.props.valueTime
        },
        this.getArrayTime
      )
    } else if (prevProps.show !== this.props.show) {
      this.getArrayTime()
    } else if (
      this.props.timesRange &&
      JSON.stringify(this.props.timesRange) !==
        JSON.stringify(prevProps.timesRange)
    ) {
      this.getArrayTime()
    }
  }

  handlePreviousYearClick = () => {
    this.setState(
      {
        year: this.state.year - 1
      },
      this.getArrayDays
    )
  }
  handleNextYearClick = () => {
    this.setState(
      {
        year: this.state.year + 1
      },
      this.getArrayDays
    )
  }

  handlePreviousMonthClick = () => {
    if (this.state.month - 1 < 1) {
      this.setState(
        {
          year: this.state.year - 1,
          month: 12
        },
        this.getArrayDays
      )
    } else {
      this.setState(
        {
          month: this.state.month - 1
        },
        this.getArrayDays
      )
    }
  }
  handleNextMonthClick = () => {
    if (this.state.month + 1 > 12) {
      this.setState(
        {
          year: this.state.year + 1,
          month: 1
        },
        this.getArrayDays
      )
    } else {
      this.setState(
        {
          month: this.state.month + 1
        },
        this.getArrayDays
      )
    }
  }

  getArrayDays = () => {
    this.holidays = hd
      .getHolidays(this.state.year)
      .map((date) => moment(date.date).format('YYYY-MM-DD'))

    const endOfMonth = parseInt(
      moment(`${this.state.year}-${this.state.month}`, 'YYYY-M')
        .endOf('month')
        .format('D')
    )

    const startDayOfMonth = moment(
      `${this.state.year}-${this.state.month}`,
      'YYYY-M'
    ).isoWeekday()

    let rows = []

    for (
      let index = startDayOfMonth - 1;
      index < endOfMonth + (startDayOfMonth - 1);
      index++
    ) {
      const col = index % 7
      const row = parseInt(index / 7)

      if (!rows.hasOwnProperty(row)) {
        rows.push([null, null, null, null, null, null, null])
      }
      rows[row][col] = index - startDayOfMonth + 2
    }

    this.setState({ days: rows })
  }

  handleActiveClick = (row, col) => {
    const currentDate = moment(
      `${this.state.year}-${this.state.month}-${this.state.days[row][col]}`,
      'YYYY-MM-D'
    ).format('YYYY-MM-DD')

    const checkActive = this.checkActive(row, col)
    if (checkActive === 1) {
      this.setState(
        {
          activeDate: null,
          activeTime: null
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(null)
          }
        }
      )
    } else if (checkActive === 0) {
      this.setState(
        {
          activeDate: currentDate,
          activeTime: null
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(currentDate)
          }
        }
      )
    }
  }

  checkActive = (row, col) => {
    const currentDate = moment(
      `${this.state.year}-${this.state.month}-${this.state.days[row][col]}`,
      'YYYY-MM-D'
    ).format('YYYY-MM-DD')

    if (this.state.activeDate === currentDate) {
      return 1
    } else {
      if (this.props.enabledDates && this.props.enabledDates.length) {
        if (
          this.props.enabledDates.includes(currentDate) &&
          !(
            this.props.minDate &&
            moment(this.props.minDate).isAfter(moment(currentDate))
          )
        ) {
          return 0
        } else {
          return 2
        }
      } else if (
        (this.props.disabledDates &&
          this.props.disabledDates.includes(currentDate)) ||
        (!this.props.isWeek &&
          [6, 7].includes(parseInt(moment(currentDate).isoWeekday()))) ||
        (!this.props.isHoliday && this.holidays.includes(currentDate)) ||
        (this.props.minDate &&
          moment(this.props.minDate).isAfter(moment(currentDate))) ||
        (this.props.maxDate &&
          moment(this.props.maxDate).isBefore(moment(currentDate))) ||
        (this.props.disabledDays &&
          this.props.disabledDays.filter(
            (element, idx) => element === 1 && idx === col
          ).length)
      ) {
        return 2
      } else {
        return 0
      }
    }
  }

  checkActiveClassName = (row, col) => {
    const checkActive = this.checkActive(row, col)
    if (checkActive === 1) {
      return style.active
    } else if (checkActive === 2) {
      return style.disabled
    } else {
      return ''
    }
  }

  handleTimeClick = (time) => {
    if (!this.props.disabledTime.includes(time)) {
      this.setState({
        activeTime: time
      })
    }
  }

  getArrayTime = () => {
    if (this.props.isTime) {
      let times = []

      this.props.timesRange.map((range) => {
        const start = moment(range[0], 'HH:mm')
        const end = moment(range[1], 'HH:mm')
        let old = start

        while (old.isBefore(end)) {
          times.push(old.format('HH:mm'))
          old = old.add(
            this.props.timeStep ? this.props.timeStep : 30,
            'minutes'
          )
        }
      })

      this.setState({
        times
      })
    } else {
      this.setState({
        times: []
      })
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    return (
      <div className={style.calendar_container}>
        <div className={style.calendar}>
          <div className={style.calendar_header}></div>
          <Row>
            <Col
              xs={12}
              md={this.props.isTime && this.state.activeDate ? 7 : 12}
            >
              <div className={style.calendar_body}>
                <div className={style.calendar_header_year}>
                  <span>{this.state.year}</span>
                  <div>
                    <span
                      className={style.calendar_switch_button}
                      onClick={this.handlePreviousYearClick}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    <span
                      className={style.calendar_switch_button}
                      onClick={this.handleNextYearClick}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </div>
                </div>
                <div className={style.calendar_header_month}>
                  <span>{this.months[parseInt(this.state.month) - 1]}</span>
                  <div>
                    <span
                      className={style.calendar_switch_button}
                      onClick={this.handlePreviousMonthClick}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    <span
                      className={style.calendar_switch_button}
                      onClick={this.handleNextMonthClick}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </div>
                </div>
                <div className={style.calendar_header_day}>
                  <table className={style.calendar_table}>
                    <thead>
                      <tr>
                        <th>Lun.</th>
                        <th>Mar.</th>
                        <th>Mer.</th>
                        <th>Jeu.</th>
                        <th>Ven.</th>
                        {this.props.isWeek ? <th>Sam.</th> : null}
                        {this.props.isWeek ? <th>Dim.</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.days.map((row, idxRow) => (
                        <tr key={idxRow}>
                          {row.map((col, idxCol) =>
                            this.props.isWeek || idxCol < 5 ? (
                              <td key={`${idxRow}-${idxCol}`}>
                                {col ? (
                                  <span
                                    className={this.checkActiveClassName(
                                      idxRow,
                                      idxCol
                                    )}
                                    onClick={() =>
                                      this.handleActiveClick(idxRow, idxCol)
                                    }
                                  >
                                    {moment(
                                      `${this.state.year}-${this.state.month}-${col}`,
                                      'YYYY-M-D'
                                    ).format('DD')}
                                  </span>
                                ) : null}
                              </td>
                            ) : null
                          )}
                        </tr>
                      ))}
                      {this.state.days.length < 6 ? <tr style={{height: "46px"}}></tr> : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            {this.props.isTime && this.state.activeDate ? (
              <Col xs={12} md={5}>
                <div className={style.calendar_time_body}>
                  {this.state.times.map((time, idx) => (
                    <div
                      key={idx}
                      className={classNames(
                        style.calendar_time_button,
                        time === this.state.activeTime ? style.active : '',
                        this.props.disabledTime.includes(time)
                          ? style.disabled
                          : ''
                      )}
                      onClick={() => this.handleTimeClick(time)}
                    >
                      <span>{time}</span>
                    </div>
                  ))}
                </div>
              </Col>
            ) : null}
          </Row>
          <div className={style.calendar_footer}>
            <div
              className={classNames(style.calendar_button, style.button_red)}
              onClick={() => this.props.onClose()}
            >
              <span>Retour</span>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div
              className={style.calendar_button}
              onClick={() =>
                this.props.onValid(this.state.activeDate, this.state.activeTime)
              }
            >
              <span>Valider</span>
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
