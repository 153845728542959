import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Td extends React.Component {
  render() {
    var tdClass = classNames({
      [style.td]: true,
      [style.spaceLeft]: !!this.props.spaceLeft,
      [style.spaceRight]: !!this.props.spaceRight,
      [style.noSpaceLeft]: !!this.props.noSpaceLeft,
      [style.noSpaceRight]: !!this.props.noSpaceRight,
      [style.radiusTopLeft]: !!this.props.radiusTopLeft,
      [style.radiusTopRight]: !!this.props.radiusTopRight,
      [style.radiusBottomLeft]: !!this.props.radiusBottomLeft,
      [style.radiusBottomRight]: !!this.props.radiusBottomRight,
      [style.hide]: !!this.props.hide,
      [this.props.className]: !!this.props.className
    })
    return (
      <td
        onClick={this.props.onClick}
        rowSpan={this.props.rowSpan}
        colSpan={this.props.colSpan}
        className={tdClass}
        style={this.props.style}
        dangerouslySetInnerHTML={this.props.dangerouslySetInnerHTML}
      >
        {this.props.children}
      </td>
    )
  }
}
