import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class CardStatistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={classNames(style.card_statistics, this.props.className)}>
        <div className={classNames(style.card_statistics_body)}>
          <span>{this.props.title}</span>
          <span>
            {this.props.subtitle}
            <span className={classNames(this.props.evolutionColor)}>
              {this.props.evolution}
            </span>
          </span>
        </div>
        {this.props.icon ? (
          <div className={classNames(style.card_statistics_icon)}>
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
        ) : null}
      </div>
    )
  }
}
