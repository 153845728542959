import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsCustomArrowCustom extends React.Component {
  render() {
    return (
      <div className={classNames(style.tabs_custom_arrow, this.props.className)}>
        {this.props.children}
      </div>
    )
  }
}
