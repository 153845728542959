import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsCustomArrowMenu extends React.Component {
  render() {
    var tabsMenuClass = classNames({
      [style.tabs_custom_arrow_menu]: true,
      [style.active]: !!this.props.active,
      [style.disabled]: !!this.props.disabled,
      [this.props.className]: !!this.props.className
    })

    return (
      <div className={tabsMenuClass} onClick={this.props.onClick}>
        <div />
        <span>{this.props.title}</span>
        <div />
      </div>
    )
  }
}
