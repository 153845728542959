import React from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import classNames from 'classNames'
import style from './style.css'

class BarChart extends React.Component {
  getData(value) {
    if (value) {
      return value
    } else {
      return 'bar'
    }
  }

  getLabel(value) {
    if (value) {
      return value
    } else {
      return 'Valeur'
    }
  }

  getColor(value) {
    if (value) {
      return value
    } else {
      return '#5c5c5c'
    }
  }

  getLabels() {
    if (this.props.labels) {
      return this.props.labels
    } else {
      return [
        'Jan.',
        'Fev.',
        'Mars',
        'Avr.',
        'Mai',
        'Juin',
        'Jui.',
        'Août',
        'Sep.',
        'Oct.',
        'Nov.',
        'Déc.'
      ]
    }
  }

  getHeight() {
    if (this.props.height) {
      return this.props.height
    } else {
      return 200
    }
  }

  getWidthBar() {
    if (this.props.widthBar) {
      return this.props.widthBar
    } else {
      return 10
    }
  }

  getIsLegend() {
    if (this.props.isLegend) {
      return this.props.isLegend
    } else {
      return false
    }
  }

  createDatasets(nbValue) {
    const dataset = []
    for (let i = 0; i < nbValue; i++) {
      const dataValue = {
        datalabels: this.props.datalabels2 ? this.props.datalabels2[i] : null,
        type: this.getData(this.props.dataSet[i].type),
        label: this.getLabel(this.props.dataSet[i].label),
        backgroundColor: this.getColor(this.props.dataSet[i].color),
        borderColor: this.getColor(
          this.props.dataSet[i].borderColor
            ? this.props.dataSet[i].borderColor
            : this.props.dataSet[i].color
        ),
        fill: this.props.dataSet[i].fill ? this.props.dataSet[i].fill : 'false',
        borderWidth: this.props.borderWidth ? this.props.borderWidth : 0,
        barThickness: this.getWidthBar(),
        barPercentage: 0.5,
        data: this.props.dataSet[i].data,
        yAxisID: this.props.dataSet[i].yAxisID
          ? this.props.dataSet[i].yAxisID
          : '',
        xAxisID: this.props.dataSet[i].xAxisID
          ? this.props.dataSet[i].xAxisID
          : ''
      }
      dataset.push(dataValue)
    }
    return dataset
  }

  render() {
    const data = {
      labels: this.getLabels(),
      datasets: this.createDatasets(this.props.dataSet.length)
    }

    return (
      <div className={classNames(style.bar_chart, this.props.className)}>
        <div className={style.bar_chart_text}>
          <span>{this.props.title}</span>
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: this.getHeight()
          }}
        >
          <Bar
            data={data}
            legend={{
              display: this.getIsLegend(),
              position: 'bottom',
              align: 'start',
              labels: { usePointStyle: true, boxWidth: 10, fontFamily: 'Arial' }
            }}
            options={{
              plugins: {
                datalabels: this.props.dataLabelsOptions
                  ? this.props.dataLabelsOptions
                  : { display: false }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: this.props.yAxes
                  ? this.props.yAxes
                  : [
                      {
                        ticks: {
                          display: false
                        }
                      }
                    ],
                xAxes: this.props.xAxes
                  ? this.props.xAxes
                  : [
                      {
                        gridLines: {
                          display: false
                        }
                      }
                    ]
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    const name = this.props.name
                      ? this.props.name
                      : data.datasets[tooltipItem.datasetIndex].label || ''
                    const digits =
                      this.props.digits >= 0 ? this.props.digits : 2
                    if (this.props.format === 'currency') {
                      const optionsCurrency = {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: digits,
                        maximumFractionDigits: digits
                      }
                      const value = new Intl.NumberFormat(
                        'fr-FR',
                        optionsCurrency
                      ).format(tooltipItem.yLabel)
                      return `${name} : ${value}`
                    } else if (this.props.format === 'percentage') {
                      const optionsPercentage = {
                        style: 'percent',
                        minimumFractionDigits: digits,
                        maximumFractionDigits: digits
                      }
                      const value = new Intl.NumberFormat(
                        'fr-FR',
                        optionsPercentage
                      ).format(tooltipItem.yLabel / 100)
                      return `${name} : ${value}`
                    } else {
                      const options = {
                        minimumFractionDigits: digits,
                        maximumFractionDigits: digits
                      }
                      const value = new Intl.NumberFormat(
                        'fr-FR',
                        options
                      ).format(tooltipItem.yLabel)
                      return `${name} : ${value}`
                    }
                  }
                }
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default BarChart
