import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FormSelect extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          style.form_select_container,
          this.props.className
        )}
      >
        {this.props.title ? (
          <span className={this.props.classNameTitle}>
            {this.props.title}{' '}
            {this.props.required ? <span className='red'>*</span> : ''}
          </span>
        ) : (
          ''
        )}
        {this.props.ignore !== true ? (
          <select
            style={this.props.styleInput}
            className={style.form_select}
            onChange={this.props.onChange}
            value={this.props.value ? this.props.value : 'DEFAULT'}
            name={this.props.name}
            required={this.props.required}
            disabled={this.props.disabled}
            defaultValue={
              this.props.defaultValue ? this.props.defaultValue : 'DEFAULT'
            }
          >
            {this.props.init ? (
              <option disabled value='DEFAULT'>
                {this.props.init}
              </option>
            ) : (
              ''
            )}
            {this.props.options
              ? this.props.options.map((element, idx) => (
                  <option
                    key={idx}
                    value={element.value}
                    disabled={element.disabled ? element.disabled : false}
                  >
                    {element.text}
                  </option>
                ))
              : ''}
          </select>
        ) : (
          <select
            style={this.props.styleInput}
            className={style.form_select}
            onChange={this.props.onChange}
            value={this.props.value ? this.props.value : 'DEFAULT'}
            name={this.props.name}
            required={this.props.required}
            disabled={this.props.disabled}
          >
            {this.props.init ? (
              <option disabled value='DEFAULT'>
                {this.props.init}
              </option>
            ) : (
              ''
            )}
            {this.props.options
              ? this.props.options.map((element, idx) => (
                  <option
                    key={idx}
                    value={element.value}
                    disabled={element.disabled ? element.disabled : false}
                  >
                    {element.text}
                  </option>
                ))
              : ''}
          </select>
        )}
        {this.props.error ? (
          <span className={style.form_select_error}>{this.props.error}</span>
        ) : (
          ''
        )}
      </div>
    )
  }
}
