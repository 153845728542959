import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import classNames from 'classNames'
import style from './style.css'

class PieChart extends React.Component {
  legendDisplayData() {
    if (this.props.legendDisplay === false) {
      return this.props.legendDisplay
    } else return true
  }

  createDatasets(nbValue) {
    const dataValue = {
      labels: [],
      datasets: [
        {
          percentage: [],
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }
      ]
    }
    for (let i = 0; i < nbValue; i++) {
      dataValue.datasets[0].datalabels = this.props.datalabels2
        ? this.props.datalabels2[i]
        : null
      dataValue.datasets[0].percentage.push(this.props.data[i].percentage)
      dataValue.datasets[0].data.push(this.props.data[i].data)
      dataValue.datasets[0].backgroundColor.push(this.props.data[i].color)
      dataValue.datasets[0].hoverBackgroundColor.push(
        this.props.data[i].color + '80'
      )
      dataValue.labels.push(this.props.data[i].labels)
    }
    return dataValue
  }

  pieForm() {
    if (this.props.full) {
      return 0
    } else {
      return 70
    }
  }

  render() {
    return (
      <div className={classNames(style.pie_chart, this.props.className)}>
        <div className={style.pie_chart_text}>
          <span>{this.props.title}</span>
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: this.props.height ? this.props.height : 200
          }}
        >
          <Doughnut
            data={this.createDatasets(this.props.data.length)}
            legend={{
              display: this.legendDisplayData(),
              position: this.props.positionLegend || 'bottom',
              align: this.props.alignLegend || 'center',
              labels: { usePointStyle: true, boxWidth: 10, fontFamily: 'Arial' }
            }}
            options={{
              plugins: {
                datalabels: this.props.dataLabelsOptions
                  ? this.props.dataLabelsOptions
                  : { display: false }
              },
              maintainAspectRatio: false,
              responsive: true,
              cutoutPercentage: this.pieForm(),
              elements: {
                arc: {
                  borderWidth: 0
                }
              },
              tooltips: {
                callbacks: {
                  label: this.props.label
                    ? this.props.label
                    : (tooltipItem, data) => {
                        const name = this.props.name
                          ? this.props.name
                          : data.labels[tooltipItem.index] || ''
                        const digits =
                          this.props.digits >= 0 ? this.props.digits : 2
                        if (this.props.format === 'currency') {
                          const optionsCurrency = {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: digits
                          }
                          const value = new Intl.NumberFormat(
                            'fr-FR',
                            optionsCurrency
                          ).format(
                            data.datasets[tooltipItem.datasetIndex].data[
                              tooltipItem.index
                            ]
                          )
                          return `${name} : ${value}`
                        } else if (this.props.format === 'percentage') {
                          const optionsPercentage = {
                            style: 'percent',
                            minimumFractionDigits: digits
                          }
                          const value = new Intl.NumberFormat(
                            'fr-FR',
                            optionsPercentage
                          ).format(
                            data.datasets[tooltipItem.datasetIndex].data[
                              tooltipItem.index
                            ] / 100
                          )
                          return `${name} : ${value}`
                        } else {
                          const options = { minimumFractionDigits: digits }
                          const value = new Intl.NumberFormat(
                            'fr-FR',
                            options
                          ).format(
                            data.datasets[tooltipItem.datasetIndex].data[
                              tooltipItem.index
                            ]
                          )
                          return `${name} : ${value}`
                        }
                      }
                }
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default PieChart
