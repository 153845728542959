import React from 'react'
import style from './style.css'

import ListingHorizontalItem from './ListingHorizontalItem'
import ListingHorizontalSeparator from './ListingHorizontalSeparator'

export default class ListingHorizontal extends React.Component {
  getData = () => {
    let tmpData = []
    const data = []

    tmpData = this.props.data.map((element, idx) => {
      return (
        <ListingHorizontalItem
          key={idx}
          green={element.green}
          orange={element.orange}
          color={element.color}
          backgroundColor={element.backgroundColor}
          title={element.title}
          subtitle={element.subtitle}
          date={element.date}
          icon={element.icon}
        />
      )
    })

    tmpData.map((element, idx) => {
      data.push(element)
    })

    return data
  }

  render() {
    const separatorWidth = `${
      this.props.data.length > 0
      ? (this.props.data.length - 1) * 140
      : 0
    }px`;

    return (
      <div className={style.listing_horizontal_container}>
        <ListingHorizontalSeparator width={separatorWidth} />
        {this.getData()}
      </div>
    )
  }
}
