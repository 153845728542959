import React from 'react'
import style from './style.css'
import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCaretDown,
  faUser
} from '@fortawesome/free-solid-svg-icons'

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      views: this.props.views,
      isHide: window.innerWidth < 640,
      isMobile: undefined
    }
  }

  componentDidMount() {
    this.setSidebar()
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  }

  componentWillUnmount() {
    removeEventListener('resize', this.checkIfMobile)
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.views) !== JSON.stringify(prevProps.views)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ views: this.props.views }, () => {
        this.setSidebar()
      })
    }
  }

  checkIfMobile = () => {
    const isMobile = window.innerWidth < 640
    if (isMobile !== this.state.isMobile) {
      this.setState(
        {
          isHide: isMobile ? true : false,
          isMobile
        },
        () => {
          if (this.props.checkIfMobile) {
            this.props.checkIfMobile(this.state.isMobile)
          }
        }
      )
    }
  }

  setSidebar = () => {
    if (this.props.location) {
      const views = this.state.views
      views.map((element, idx) => {
        if (element.path) {
          if (element.path === this.props.location.pathname) {
            views[idx].active = true
          } else {
            views[idx].active = false
          }
        } else {
          element.views.map((elementDropdown, idxDropdown) => {
            if (elementDropdown.path === this.props.location.pathname) {
              views[idx].active = true
              views[idx].views[idxDropdown].active = true
            } else {
              views[idx].views[idxDropdown].active = false
            }
          })
        }
      })
      this.setState({ views: views })
    }
  }

  handleDropdownClick(id) {
    const views = this.state.views
    views[id].active = !views[id].active
    this.setState({ views: views })
  }

  handleChangeViewClick(path, id, idDropdown = -1) {
    if (
      (idDropdown >= 0 && !this.state.views[id].views[idDropdown].active) ||
      !this.state.views[id].active
    ) {
      const views = this.state.views
      views.map((element, idx) => {
        if (element.active === true) {
          views[idx].active = false
        }
        if (element.views) {
          element.views.map((elementDropdown, idxDropdown) => {
            if (elementDropdown.active === true) {
              views[idx].views[idxDropdown].active = false
            }
          })
        }
      })
      if (idDropdown < 0) {
        views[id].active = true
      } else {
        views[id].active = true
        views[id].views[idDropdown].active = true
      }
      this.setState({ views: views })
      this.props.onViewChange({ path: path, views: views })
    }
  }

  handleHideClick = () => {
    this.setState({ isHide: !this.state.isHide }, () => {
      if (this.props.onHide) {
        this.props.onHide(this.state.isHide)
      }
    })
  }

  render() {
    var sidebarClass = classNames(
      {
        [style.small]: !!this.state.isHide,
        [style.mobile]: !!this.state.isMobile
      },
      style.sidebar,
      this.props.className
    )

    return (
      <div className={sidebarClass}>
        <div className={style.sidebar_header}>
          <div className={style.sidebar_icon}>
            {this.props.icon ? <img alt='icon' src={this.props.icon} /> : null}
          </div>
          <div className={style.button_hide} onClick={this.handleHideClick}>
            {this.state.isHide ? (
              <FontAwesomeIcon icon={faCaretRight} />
            ) : (
              <FontAwesomeIcon icon={faCaretLeft} />
            )}
          </div>
        </div>
        <div className={style.sidebar_user}>
          {this.props.picture ? (
            <div className={style.sidebar_user_icon}>
              <img src={this.props.picture} alt={'profile'} />
            </div>
          ) : (
            <FontAwesomeIcon
              className={classNames({
                'm-auto': this.state.isHide === true,
                [style.sidebar_user_icon]: true
              })}
              icon={faUser}
            />
          )}
          {this.state.isHide === false ? (
            <div className={style.sidebar_user_text}>
              <span className={style.sidebar_user_title}>
                {this.props.title}
              </span>
              <span className={style.sidebar_user_subtitle}>
                {this.props.subtitle}
              </span>
            </div>
          ) : null}
        </div>
        <div className={style.sidebar_body}>
          {this.state.views.map((element, idx) => {
            if (element.path && element.isHide !== true) {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    if (
                      !element.disabled ||
                      (element.disabled && this.props.allowDisabledClick)
                    ) {
                      this.handleChangeViewClick(element.path, idx)
                    }
                  }}
                  className={classNames(style.sidebar_button, {
                    [style.active]: !!element.active,
                    [style.disabled]: !!element.disabled
                  })}
                >
                  {element.icon}
                  <span>{element.name}</span>
                </div>
              )
            } else if (element.isHide !== true) {
              const data = []
              data.push(
                <div
                  key={idx}
                  onClick={() => this.handleDropdownClick(idx)}
                  className={classNames(style.sidebar_button_dropdown, {
                    [style.active]: !!element.active,
                    [style.disabled]: !!element.disabled
                  })}
                >
                  {element.icon}
                  <span>{element.name}</span>
                  {element.active === true ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretUp} />
                  )}
                </div>
              )
              element.views.map((elementDropdown, idxDropdown) => {
                if (
                  element.active === true &&
                  elementDropdown.isHide !== true
                ) {
                  data.push(
                    <div
                      key={idx + '-' + idxDropdown}
                      onClick={() => {
                        if (
                          !elementDropdown.disabled ||
                          (elementDropdown.disabled &&
                            this.props.allowDisabledClick)
                        ) {
                          this.handleChangeViewClick(
                            elementDropdown.path,
                            idx,
                            idxDropdown
                          )
                        }
                      }}
                      className={classNames(
                        style.sidebar_button,
                        style.sidebar_button_second,
                        {
                          [style.active]: !!elementDropdown.active,
                          [style.disabled]: !!elementDropdown.disabled
                        }
                      )}
                    >
                      {elementDropdown.icon}
                      <span>{elementDropdown.name}</span>
                    </div>
                  )
                }
              })
              return data
            }
          })}
        </div>
        {this.props.children}
      </div>
    )
  }
}
