import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class ButtonIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHover: false
    }
  }
  render() {
    var buttonClass = classNames({
      [style.button_icon]: true,
      [style.white]: !!this.props.white,
      [style.small]: !!this.props.small,
      [style.big]: !!this.props.big,
      [this.props.className]: !!this.props.className,
      [style.button_icon_background]: !!this.props.isBackground
    })

    return (
      <button
        className={buttonClass}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        style={this.props.style}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        {this.props.children}
        {this.props.info && this.state.isHover ? (
          <span
            style={{
              position: 'absolute',
              padding: '4px',
              borderRadius: '4px',
              backgroundColor: 'var(--primary-color)',
              bottom: this.props.isBackground ? '-22px' : '-16px',
              left: '50%',
              transform: 'translate(-50%, 0)',
              color: '#ffffff',
              fontSize: '9px',
              minWidth: '100%',
              whiteSpace: 'nowrap'
            }}
          >
            {this.props.info}
          </span>
        ) : null}
      </button>
    )
  }
}
