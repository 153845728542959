import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsCustomArrowItem extends React.Component {
  render() {
    var tabsItemClass = classNames({
      [style.tabs_custom_arrow_item]: true,
      [style.active]: !!this.props.active,
      [this.props.className]: !!this.props.className
    })

    return <div className={tabsItemClass}>{this.props.children}</div>
  }
}
