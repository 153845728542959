import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Tr extends React.Component {
  render() {
    return (
      <tr
        className={classNames(style.tr, this.props.className)}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </tr>
    )
  }
}
