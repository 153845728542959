import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class AlertElement extends React.Component {
  render() {
    return (
      <div
        className={classNames(style.alert_element, this.props.backgroundColor)}
      >
        <span
          style={this.props.bold ? { fontWeight: 'bold' } : {}}
          className={this.props.textColor}
        >
          {this.props.children}
        </span>
        <span
          className={classNames(style.alert_element_icon, this.props.textColor)}
        >
          {this.props.icon}
        </span>
      </div>
    )
  }
}
