import React from 'react'
import PaginationCMP from 'react-js-pagination'
import classNames from 'classnames'
import style from './style.css'

class Pagination extends React.Component {
  render() {
    return (
      <div
        className={classNames(style.paginationContainer, this.props.className)}
      >
        <PaginationCMP
          innerClass={style.pagination}
          activeClass={style.active}
          disabledClass={style.disabled}
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsCountPerPage}
          totalItemsCount={this.props.totalItemsCount}
          pageRangeDisplayed={this.props.pageRangeDisplayed}
          onChange={this.props.onChange}
          firstPageText={this.props.firstPageText}
          lastPageText={this.props.lastPageText}
          prevPageText={this.props.prevPageText}
          nextPageText={this.props.nextPageText}
          hideNavigation={this.props.hideNavigation}
          hideFirstLastPages={this.props.hideFirstLastPages}
        />
      </div>
    )
  }
}

export default Pagination
