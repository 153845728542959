import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import ButtonIcon from '../button/ButtonIcon'

import style from './style.css'

export default class Header extends React.Component {
  render() {
    return (
      <div className={classNames(style.header, this.props.className, 'sky')}>
        <div
          className={style.header_absolute}
          style={this.props.styleBackground}
        />
        <div className={style.header_left}>
          {this.props.logo ? (
            <img
              src={this.props.logo}
              height={this.props.height}
              onClick={this.props.onClick}
              className={this.props.onClick ? 'cursor-pointer' : ''}
            />
          ) : null}
          {this.props.title ? <h1>{this.props.title}</h1> : null}
        </div>
        <div className={style.header_right}>
          {this.props.sidebarRef && this.props.isMobile ? (
            <ButtonIcon onClick={this.props.sidebarRef.current.handleHideClick}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </ButtonIcon>
          ) : null}
          {this.props.children}
        </div>
      </div>
    )
  }
}
