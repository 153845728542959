import React, { createRef } from 'react'
import style from './style.css'
import classNames from 'classnames'

class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: null
    }
    this.refProgress = createRef()
  }

  componentDidMount() {
    const max = this.props.max ? this.props.max : 100
    let value = this.props.value ? this.props.value : 0
    if (value > max) {
      value = max
    }
    this.setState({ width: this.getWidth(value, max) })
    window.addEventListener('resize', () => {
      this.setState({ width: this.getWidth(value, max) })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const max = this.props.max ? this.props.max : 100
      let value = this.props.value ? this.props.value : 0
      if (value > max) {
        value = max
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ width: this.getWidth(value, max) })
    }
  }

  getColor(value, max) {
    const percentage = (value * 100) / max
    if (percentage >= 90) {
      return 'bg-green'
    } else if (percentage >= 50) {
      return 'bg-orange'
    } else {
      return 'bg-red'
    }
  }

  getWidth(value, max) {
    const percentage = (value * 100) / max
    if (this.refProgress.current) {
      // const width = this.refProgress.current.offsetWidth
      return percentage + '%'
    } else {
      return '0px'
    }
  }

  render() {
    const max = this.props.max ? this.props.max : 100
    const value = this.props.value ? this.props.value : 0
    return (
      <div
        className={classNames(
          style.progress_bar_container,
          this.props.className
        )}
      >
        <span>{this.props.title}</span>
        <div className={style.bar} ref={this.refProgress}>
          <div
            className={classNames(
              style.custom_progress,
              this.getColor(value, max)
            )}
            style={{ width: this.state.width }}
          />
          <span>{this.props.text ? this.props.text : value + ' / ' + max}</span>
        </div>
      </div>
    )
  }
}

export default ProgressBar
