import React, { Fragment } from 'react'
import style from './style.css'
import classNames from 'classnames'

import Button from '../button/Button'

export default class Table extends React.Component {
  getArray = (data) => {
    if (data && Array.isArray(data)) {
      return data
    } else if (data) {
      return [data]
    } else {
      return []
    }
  }

  getCSV = (callback) => {
    let dataCSV = ''

    if (this.props && this.props.children) {
      this.getArray(this.props.children).forEach((children) => {
        if (children.props && children.props.children) {
          this.getArray(children.props.children).forEach((line) => {
            if (line.props && line.props.children) {
              this.getArray(line.props.children).forEach((column) => {
                if (column.props && column.props.children) {
                  dataCSV += `${column.props.children.toString()};`
                } else {
                  dataCSV += ';'
                }
              })
              dataCSV += '\n'
            }
          })
        }
      })
    }

    const link = document.createElement('a')
    link.href = `data:text/csv;charset=utf-8,\uFEFF${encodeURIComponent(
      dataCSV
    )}`
    link.download = this.props.nameCSV || 'file.csv'
    link.click()

    callback()
  }

  render() {
    const tableClass = classNames({
      [style.table]: true,
      [style.tableEmpty]: !!this.props.empty,
      [style.radiusTopLeft]: !!this.props.radiusTopLeft,
      [style.radiusTopRight]: !!this.props.radiusTopRight,
      [this.props.className]: !!this.props.className
    })

    return (
      <Fragment>
        {this.props.isCSV ? (
          <div className='d-flex'>
            <Button
              text='Télécharger CSV'
              className='w-auto ml-auto mb-10'
              onClick={() => {
                if (this.props.getAllData) {
                  this.props.getAllData(() =>
                    this.getCSV(this.props.csvCallback)
                  )
                } else {
                  this.getCSV(this.props.csvCallback)
                }
              }}
            />
          </div>
        ) : null}
        <table className={tableClass} style={this.props.style}>
          {this.props.children}
          {this.props.empty ? (
            <tbody>
              <tr>
                <td colSpan='100'>
                  <div className={style.trEmpty}>{this.props.empty}</div>
                </td>
              </tr>
            </tbody>
          ) : (
            false
          )}
        </table>
      </Fragment>
    )
  }
}
