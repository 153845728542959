import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsMenu extends React.Component {
  render() {
    var tabsMenuClass = classNames({
      [style.tabs_menu]: true,
      [style.active]: !!this.props.active,
      [this.props.className]: !!this.props.className
    })

    return (
      <div className={tabsMenuClass} onClick={this.props.onClick}>
        <span>{this.props.title}</span>
      </div>
    )
  }
}
