import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Icon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refIcon: React.createRef(),
      colorStyle: null
    }
  }

  componentDidMount() {
    this.setState({
      colorStyle: window
        .getComputedStyle(this.state.refIcon.current, null)
        .getPropertyValue('color')
    })
  }

  render() {
    return (
      <div
        ref={this.state.refIcon}
        className={classNames(this.props.className)}
        style={{ fontSize: this.props.size ? this.props.size + 'px' : 'none' }}
      >
        {this.props.icon({
          className: classNames(style.icon),
          color: this.state.colorStyle
        })}
      </div>
    )
  }
}
