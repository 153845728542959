import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Thead extends React.Component {
  render() {
    return (
      <thead
        className={classNames(style.thead, this.props.className)}
        style={this.props.style}
      >
        {this.props.children}
      </thead>
    )
  }
}
