import React from 'react'
import style from './style.css'

import ListingVerticalItem from './ListingVerticalItem'
import ListingVerticalSeparator from './ListingVerticalSeparator'

export default class ListingVertical extends React.Component {
  getData = () => {
    let tmpData = []
    const data = []

    tmpData = this.props.data.map((element, idx) => {
      return (
        <ListingVerticalItem
          key={idx}
          green={element.green}
          orange={element.orange}
          color={element.color}
          backgroundColor={element.backgroundColor}
          title={element.title}
          subtitle={element.subtitle}
          date={element.date}
          icon={element.icon}
        />
      )
    })

    tmpData.map((element, idx) => {
      data.push(element)
      if (idx < tmpData.length - 1) {
        data.push(<ListingVerticalSeparator key={'s-' + idx} />)
      }
    })

    return data
  }

  render() {
    return (
      <div className={style.listing_vertical_container}>{this.getData()}</div>
    )
  }
}
