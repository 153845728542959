import React from 'react'
import style from './style.css'

export default class ListingVerticalSeparator extends React.Component {
  render() {
    return (
      <div className={style.listing_vertical_separator}>
        <span>|</span>
      </div>
    )
  }
}
