import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsHeader extends React.Component {
  render() {
    return (
      <div className={classNames(style.tabs_header, this.props.className)}>
        {this.props.children}
      </div>
    )
  }
}
