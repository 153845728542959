import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'

export default class Nav extends React.Component {
  render() {
    return (
      <div className={classNames(style.nav, this.props.className)}>
        <div className={style.nav_left}>
          {this.props.back ? (
            <div className={style.nav_back} onClick={this.props.onBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          ) : (
            ''
          )}
          <div className={style.nav_text}>
            <h2>{this.props.title}</h2>
            <span>{this.props.subtitle}</span>
          </div>
        </div>
        <div className={style.nav_right}>{this.props.children}</div>
      </div>
    )
  }
}
