import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Alert extends React.Component {
  componentDidMount() {
    if (this.props.onTimeout) {
      setTimeout(
        () => {
          this.props.onTimeout(this.props.id)
        },
        this.props.timeout ? this.props.timeout : 2000
      )
    }
  }

  render() {
    return (
      <div className={classNames(style.alert, this.props.backgroundColor)}>
        <span className={this.props.textColor}>{this.props.text}</span>
      </div>
    )
  }
}
