import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default class ListingHorizontalItem extends React.Component {
  render() {
    var listingClass = classNames({
      [style.listing_horizontal]: true,
      [style.listing_green]: !!this.props.green,
      [style.listing_orange]: !!this.props.orange
    })

    return (
      <div className={listingClass}>
        <div
          className={style.listing_horizontal_icon}
          style={{
            backgroundColor: this.props.backgroundColor
              ? this.props.backgroundColor
              : 'gray',
            color: this.props.color ? this.props.color : 'white'
          }}
        >
          {this.props.icon ? (
            this.props.icon
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </div>
        <div className={style.listing_horizontal_text}>
          <span>{this.props.date}</span>
          <span>{this.props.title}</span>
          <span>{this.props.subtitle}</span>
        </div>
      </div>
    )
  }
}
