import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'

export default class Slider extends React.Component {
  render() {
    return (
      <div className={classNames(style.slider, this.props.className)}>
        <div className={style.slider_button}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <span className={style.slider_value}>{this.props.value}</span>
        <div className={style.slider_button}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    )
  }
}
