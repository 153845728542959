import React, { Component } from 'react'
import style from './style.css'
import classNames from 'classnames'

export class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHide: true
    }
  }

  render() {
    var dropdownMenuClass = classNames({
      [style.dropdown_menu]: true,
      [style.show]: !this.state.isHide
    })

    return (
      <div className={classNames(style.dropdown)}>
        <div
          className={classNames(style.dropdown_first)}
          onClick={() => this.setState({ isHide: !this.state.isHide })}
        >
          {this.props.title ? <span>{this.props.title}</span> : <></>}
          {this.props.title && this.props.icon ? (
            <div className={classNames(style.space)} />
          ) : (
            <></>
          )}
          {this.props.icon ? this.props.icon : <></>}
        </div>
        <div className={dropdownMenuClass}>
          {this.props.data.map((element, key) => (
            <span key={key}>{element.title}</span>
          ))}
        </div>
      </div>
    )
  }
}

export default Dropdown
