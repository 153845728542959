import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FormRadio extends React.Component {
  render() {
    return (
      <div className={classNames(style.radio_container, this.props.className)}>
        <label className={classNames(style.form_radio_container)}>
          {this.props.text}
          <input
            type='radio'
            name={this.props.name}
            value={this.props.value}
            checked={this.props.checked}
            onChange={this.props.onChange}
          />
          <span className={style.form_radio_mark} />
        </label>
      </div>
    )
  }
}
