import React from 'react'
import classNames from 'classNames'
import style from './style.css'

class Status extends React.Component {
  render() {
    return (
      <div
        className={classNames(style.status, this.props.className)}
        style={this.props.style}
      >
        {this.props.text}
      </div>
    )
  }
}

export default Status
