import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { Row, Col } from 'react-flexbox-grid'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={style.footer}>
        <Row>
          <Col xs={12} md={12} lg={4} className='mt-5 mb-5 d-flex'>
            <img className='m-auto' src={this.props.logo} />
          </Col>
          <Col xs={12} md={6} lg={4} className='mt-5 mb-5 d-flex'>
            <a
              className='m-auto'
              target='_blank'
              href={this.props.legal}
              onClick={this.props.onLegalClick}
            >
              Mentions Légales
            </a>
          </Col>
          <Col xs={12} md={6} lg={4} className='mt-5 mb-5 d-flex'>
            <a
              className='m-auto'
              target='_blank'
              href={this.props.rgpd}
              onClick={this.props.onRgpdClick}
            >
              RGPD & Cookies
            </a>
          </Col>
          {this.props.socialNetwork ? (
            <Col xs={12} className='mt-5 mb-5 text-center'>
              {this.props.socialNetwork}
            </Col>
          ) : null}
        </Row>
      </div>
    )
  }
}
