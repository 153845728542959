import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faBan } from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'

export default class Signature extends React.Component {
  render() {
    var signatureClass = classNames({
      [style.signature]: true,
      [style.active]: !!this.props.signature,
      [style.small]: !!this.props.small,
      [style.big]: !!this.props.big,
      [this.props.className]: !!this.props.className
    })

    if (this.props.signature) {
      return (
        <div
          className={signatureClass}
          style={{ height: this.props.height ? this.props.height : '200px' }}
        >
          {!this.props.disabled ? (
            <div
              className={style.signature_remove}
              onClick={this.props.onRemove}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : (
            ''
          )}
          <img src={this.props.signature} alt='signature' />
        </div>
      )
    } else {
      return (
        <div
          className={signatureClass}
          style={{ height: this.props.height ? this.props.height : '200px' }}
        >
          {!this.props.disabled ? (
            <div className={style.signature_add} onClick={this.props.onAdd}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          ) : (
            <div className={style.signature_add} style={{ cursor: "default" }}>
              <FontAwesomeIcon icon={faBan} />
            </div>
          )}
        </div>
      )
    }
  }
}
