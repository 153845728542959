import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Switch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idxActive: 0
    }
  }

  componentDidMount() {
    if (this.props.idxActive !== undefined) {
      this.setState({
        idxActive: this.props.idxActive
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.idxActive !== undefined &&
      prevProps.idxActive !== this.props.idxActive &&
      this.props.idxActive !== this.state.prevProps
    ) {
      this.setState({
        idxActive: this.props.idxActive
      })
    }
  }

  render() {
    return (
      <div className={classNames(style.switch_container, this.props.className)}>
        {this.props.title ? (
          <span className={this.props.classNameTitle}>{this.props.title}</span>
        ) : null}
        <div className={classNames(style.switch)}>
          <div
            onClick={() =>
              this.setState({ idxActive: 0 }, () =>
                this.props.onChange ? this.props.onChange(0) : null
              )
            }
            style={
              this.state.idxActive === 0
                ? { backgroundColor: this.props.color1, color: 'var(--white)' }
                : {}
            }
          >
            <span>{this.props.value1}</span>
          </div>
          <div
            onClick={() =>
              this.setState({ idxActive: 1 }, () =>
                this.props.onChange ? this.props.onChange(1) : null
              )
            }
            style={
              this.state.idxActive === 1
                ? { backgroundColor: this.props.color2, color: 'var(--white)' }
                : {}
            }
          >
            <span>{this.props.value2}</span>
          </div>
        </div>
      </div>
    )
  }
}
