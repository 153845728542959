import React, { Component } from 'react'
import { Radar } from "react-chartjs-2"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import classNames from 'classNames'
import style from './style.css'

export class RadarChart extends Component {
  
  getLabels = () => {
    return this.props.labels
  }

  getLabel(value) {
    if (value) {
      return value
    } else {
      return 'Valeur'
    }
  }

  getColor(value) {
    if (value) {
      return value
    } else {
      return '#5c5c5c'
    }
  }

  getHeight() {
    if (this.props.height) {
      return this.props.height
    } else {
      return 350
    }
  }

  getIsLegend() {
    if (this.props.isLegend) {
      return this.props.isLegend
    } else {
      return false
    }
  }

  createDatasets = (nbValue) => {
    const dataset = []
    for (let i = 0; i < nbValue; i++) {
      const dataValue = {
        datalabels: this.props.datalabels2 ? this.props.datalabels2[i] : null,
        label: this.getLabel(this.props.dataSet[i].label),
        backgroundColor: 'rgba(200, 200, 200, 0.2)',
        borderColor: this.getColor(this.props.dataSet[i].color),
        data: this.props.dataSet[i].data,
      }
      dataset.push(dataValue)
    }
    return dataset
  }

  render() {
    const options = {
      plugins: {
        datalabels: this.props.dataLabelsOptions
          ? this.props.dataLabelsOptions
          : { display: false }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: this.getIsLegend(),
        position: 'bottom',
        labels: {  boxWidth: 10, fontFamily: 'Arial' },
        legendCallback: function(test)  {
          console.log('TEST')
          console.log('test', test)
        }
      },
      scale: {
        reverse: false,
        ticks: {
          display: false,
          beginAtZero: true
        }
      },
    }
    const data = {
      labels: this.getLabels(),
      datasets: this.createDatasets(this.props.dataSet.length)
    }

    return (
      <div className={classNames(style.bar_chart, this.props.className)}>
        <div className={style.bar_chart_text}>
          <span>{this.props.title}</span>
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: this.getHeight()
          }}
        >
        <Radar 
          data={data}
          options={options}
        />
      </div>
    </div>
    )
  }
}

export default RadarChart
