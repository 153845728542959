import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FloatActionButton extends React.Component {
  render() {
    var floatActionClass = classNames({
      [style.float_action]: true,
      [this.props.className]: !!this.props.className
    })

    return (
      <div className={floatActionClass}>
        <div className={classNames(style.float_action_container)}>
          {this.props.children}
        </div>
        <button
          className={classNames(style.float_action_button)}
          onClick={this.props.onClick}
        >
          {this.props.icon}
        </button>
      </div>
    )
  }
}
