import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import style from './style.css'
import classNames from 'classnames'

class DoughnutChart extends React.Component {
  getColor(value, max) {
    const percentage = (value * 100) / max
    if (percentage >= (this.props.high ? this.props.high : 90)) {
      return this.props.reverse ? '#db2828' : '#0c8540'
    } else if (percentage >= (this.props.medium ? this.props.medium : 80)) {
      return '#f2711c'
    } else {
      return this.props.reverse ? '#0c8540' : '#db2828'
    }
  }

  render() {
    const num = this.props.number
    const max = this.props.max ? this.props.max : 100
    const difNumber = max - this.props.number
    const text = this.props.text
    const unit = this.props.unit ? this.props.unit : '%'

    const data = {
      labels: ['Red', 'Green'],
      datasets: [
        {
          datalabels2: this.props.datalabels2,
          data: [this.props.number, difNumber],
          backgroundColor: [this.getColor(num, max), '#f5f5f5'],
          hoverBorderWidth: 0,
          borderWidth: 0
        }
      ]
    }
    return (
      <div className={classNames(style.doughnut_chart, this.props.className)}>
        <Doughnut
          id={this.props.text}
          data={data}
          width={145}
          height={145}
          legend={{ display: false, labels: { boxWidth: 0 } }}
          options={{
            plugins: {
              datalabels: this.props.dataLabelsOptions
                ? this.props.dataLabelsOptions
                : { display: false }
            },
            responsive: true,
            maintainAspectRatio: true,
            cutoutPercentage: 90,
            tooltips: { enabled: false }
          }}
        />
        <div className={style.text_doughnut_chart}>
          <p
            style={{ color: this.getColor(num, max) }}
            className={style.number}
          >
            {num}
            {unit}
          </p>
          <p style={{ color: this.getColor(num, max) }} className={style.text}>
            {text}
          </p>
        </div>
      </div>
    )
  }
}

export default DoughnutChart
