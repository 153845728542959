import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsCustomHeader extends React.Component {
  render() {
    var tabsHeaderClass = classNames({
      [style.tabs_custom_header]: true,
      [style.noCard]: !!this.props.noCard,
      [this.props.className]: !!this.props.className
    })

    return (
      <div
        className={tabsHeaderClass}
      >
        {this.props.children}
      </div>
    )
  }
}
