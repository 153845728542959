import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import Button from '../button/Button'

export default class Error404 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={classNames(style.error_full)}>
        <div className={classNames(style.error_center)}>
          <div className={classNames(style.error_center_group)}>
            <h3 className={classNames(style.error_title)}>Page not found</h3>
            <h1 className={classNames(style.error_code)}>
              <span style={{ 'text-shadow': '-8px 0 0 #fff' }}>4</span>
              <span style={{ 'text-shadow': '-8px 0 0 #fff' }}>0</span>
              <span style={{ 'text-shadow': '-8px 0 0 #fff' }}>4</span>
            </h1>
          </div>
          <h2 className={classNames(style.error_text)}>
            {this.props.text ? this.props.text : "La page n'existe pas"}
          </h2>
          <Button
            onClick={() =>
              this.props.onBack ? this.props.onBack() : window.history.back()
            }
            text='Retour'
            outline
          />
        </div>
      </div>
    )
  }
}
