import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class TabsEditBody extends React.Component {
  render() {
    var tabsBodyClass = classNames({
      [style.tabs_edit_body]: true,
      [style.noCard]: !!this.props.noCard,
      [this.props.className]: !!this.props.className
    })

    return <div className={tabsBodyClass}>{this.props.children}</div>
  }
}
