import React, { Fragment } from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import FormInput from '../form-input/FormInput'
import FormSelect from '../form-select/FormSelect'

export default class FormRange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.refComponent = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (
      this.refComponent &&
      this.refComponent.current &&
      !this.refComponent.current.contains(event.target)
    ) {
      this.setState({ isOpen: false })
    }
  }

  findText = (options, value) => {
    if (this.props.input) {
      return value
    } else {
      const result = options.filter(
        (item) => item.value.toString() === value.toString()
      )

      return result.length ? result[0].text : ''
    }
  }

  getLabel = (value1, value2, options1, options2) => {
    if (this.findText(options1, value1) === this.findText(options2, value2)) {
      return `${this.findText(options1, value1)}`
    } else if (
      value1 !== 'DEFAULT' &&
      value1 !== '' &&
      (value2 === 'DEFAULT' || value2 === '')
    ) {
      return `>= ${this.findText(options1, value1)}`
    } else if (
      (value1 === 'DEFAULT' || value1 === '') &&
      value2 !== 'DEFAULT' &&
      value2 !== ''
    ) {
      return `<= ${this.findText(options2, value2)}`
    } else {
      return `${this.findText(options1, value1)} - ${this.findText(
        options2,
        value2
      )}`
    }
  }

  render() {
    return (
      <div
        className={classNames(style.form_range_container, this.props.className)}
      >
        {this.props.title ? (
          <span className={this.props.classNameTitle}>
            {this.props.title}{' '}
            {this.props.required ? <span className='red'>*</span> : ''}
          </span>
        ) : null}
        <div
          className={style.form_range}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <span>
            {this.getLabel(
              this.props.value1,
              this.props.value2,
              this.props.options1,
              this.props.options2
            )}
          </span>
          <div>
            <FontAwesomeIcon
              icon={this.state.isOpen ? faCaretUp : faCaretDown}
            />
          </div>
        </div>
        {this.state.isOpen ? (
          <div ref={this.refComponent} className={style.form_range_modal}>
            {this.props.input ? (
              <Fragment>
                <FormInput
                  type={this.props.type}
                  name={this.props.name1}
                  value={this.props.value1}
                  onChange={this.props.onChange1}
                  className='mr-10 w-100'
                />
                <FormInput
                  type={this.props.type}
                  name={this.props.name2}
                  value={this.props.value2}
                  onChange={this.props.onChange2}
                  className='w-100'
                />
              </Fragment>
            ) : (
              <Fragment>
                <FormSelect
                  type={this.props.type}
                  name={this.props.name1}
                  options={this.props.options1}
                  value={this.props.value1}
                  onChange={this.props.onChange1}
                  className='mr-10 w-100'
                  ignore={true}
                  disabled={false}
                  init={this.props.init1}
                />
                <FormSelect
                  type={this.props.type}
                  name={this.props.name2}
                  options={this.props.options2}
                  value={this.props.value2}
                  onChange={this.props.onChange2}
                  className='w-100'
                  ignore={true}
                  disabled={false}
                  init={this.props.init2}
                />
              </Fragment>
            )}
          </div>
        ) : null}
      </div>
    )
  }
}
