import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import classNames from 'classNames'
import style from './style.css'

class HorizontalBarChart extends React.Component {
  scalesType() {
    if (this.props.align) {
      return {
        xAxes: [
          {
            stacked: true,
            offset: true,
            ticks: {
              display: false
            },
            barThickness: 50,
            gridLines: {
              borderDash: [4, 4],
              color: '#d8d8d8'
            }
          }
        ],
        yAxes: [
          {
            stacked: true,
            offset: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }
        ]
      }
    } else
      return {
        xAxes: [
          {
            gridLines: {
              borderDash: [4, 4],
              color: '#d8d8d8'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      }
  }

  typeLabel(value) {
    if (value) {
      return value
    } else return 'Value'
  }

  typeColor(value) {
    if (value) {
      return value
    } else return '#5c5c5c'
  }

  labelsData() {
    if (this.props.labels) {
      return this.props.labels
    } else
      return [
        'Jan.',
        'Fev.',
        'Mars',
        'Avr.',
        'Mai',
        'Juin',
        'Jui.',
        'Août',
        'Sep.',
        'Oct.',
        'Nov.',
        'Déc.'
      ]
  }

  widthData() {
    if (this.props.width) {
      return this.props.width
    }
  }

  heightData() {
    if (this.props.height) {
      return this.props.height
    }
  }

  legendDisplayData() {
    if (this.props.legendDisplay) {
      return this.props.legendDisplay
    } else return false
  }

  createDatasets(nbValue) {
    const dataset = []
    for (let i = 0; i < nbValue; i++) {
      const dataValue = {
        datalabels: this.props.datalabels2 ? this.props.datalabels2[i] : null,
        label: this.typeLabel(this.props.dataSet[i].label),
        backgroundColor: this.typeColor(this.props.dataSet[i].color),
        borderColor: this.typeColor(this.props.dataSet[i].color),
        barThickness: this.getWidthBar(),
        fill: 'false',
        borderWidth: 0,
        barPercentage: 0.5,
        data: this.props.dataSet[i].data
      }
      dataset.push(dataValue)
    }
    return dataset
  }



  getWidthBar() {
    if (this.props.widthBar) {
      return this.props.widthBar
    } else {
      return 10
    }
  }

  render() {
    const data = {
      labels: this.labelsData(),
      datasets: this.createDatasets(this.props.dataSet.length)
    }

    return (
      <div className={classNames(style.bar_chart, this.props.className)}>
        <div className={style.bar_chart_text}>
          <span>{this.props.title}</span>
        </div>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: this.props.height ? this.props.height : 200
          }}
        >
          <HorizontalBar
            style={{ margin: 'auto' }}
            data={data}
            legend={{
              display: this.legendDisplayData(),
              position: 'bottom',
              align: 'start',
              labels: { usePointStyle: true, boxWidth: 10, fontFamily: 'Arial' }
            }}
            options={{
              plugins: {
                datalabels: this.props.dataLabelsOptions
                  ? this.props.dataLabelsOptions
                  : { display: false }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: this.scalesType()
            }}
          />
        </div>
      </div>
    )
  }
}

export default HorizontalBarChart
